<template src="./Event.html"></template>

<script>
import { mapGetters } from "vuex";
import eventsService from "../../services/Events";
import configService from "../../configs/config";

export default {
  name: "Event",
  data() {
    return {
      api: [],
      event: {},
      showVideoText: "Activar Camara",
      showAudioText: "Activar Microfono",
      shareScreenText: "Compartir Pantalla",
      userInfo: {},
      room: [],
      getParticipantsInfo: [],
      isModerator: false,
      isComponentModalActive: true,
      code: "",
    };
  },
  created() {
    this.getEvent();
  },
  methods: {
    validateModerador() {
      if (this.user.id === this.event.moderator.id) {
        this.isModerator = true;
      }
    },

    async getEvent() {
      this.event = await eventsService.getEvent(this.$route.params.idEvent);
      this.validateModerador();
    },

    async validateCode(code) {
      try {
        await eventsService.validateEventCode(this.$route.params.idEvent, code);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Ingresando a evento!",
          type: "is-success",
        });
        this.createdJetsi(this.event);
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: e.response.data.message,
          type: "is-danger",
        });
      }
    },
    createdJetsi(event) {
      const domain = configService.apiJitsi;
      const options = {
        roomName: event.meetUrl,
        height: 700,
        configOverwrite: {
          startWithAudioMuted: true,
          startWithVideoMuted: true,
        },
        interfaceConfigOverwrite: { DISABLE_DOMINANT_SPEAKER_INDICATOR: true },
        parentNode: document.querySelector("#meet"),
        userInfo: {
          displayName: this.user.name,
        },
      };
      const api = new JitsiMeetExternalAPI(domain, options);
      this.api = api;
      if (this.api) {
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Ingreso a la sala con exito!",
          type: "is-success",
        });
        this.isComponentModalActive = false;
      }
    },
    buttonShowVideo(showVideoText) {
      if (showVideoText === "Desactivar Camara") {
        this.api.executeCommand("toggleVideo");
        this.showVideoText = "Activar Camara";
      } else if (showVideoText === "Activar Camara") {
        this.api.executeCommand("toggleVideo");
        this.showVideoText = "Desactivar Camara";
      }
    },

    buttonShowAudio(showAudioText) {
      if (showAudioText === "Activar Microfono") {
        this.api.executeCommand("toggleAudio");
        this.showAudioText = "Desactivar Microfono";
      } else if (showAudioText === "Desactivar Microfono") {
        this.api.executeCommand("toggleAudio");
        this.showAudioText = "Activar Microfono";
      }
    },

    buttonShareScreen(shareScreenText) {
      if (shareScreenText === "Compartir Pantalla") {
        this.api.executeCommand("toggleShareScreen");
        this.shareScreenText = "Quitar Pantalla";
      } else if (shareScreenText === "Quitar Pantalla") {
        this.api.executeCommand("toggleShareScreen");
        this.shareScreenText = "Compartir Pantalla";
      }
    },
    async finishEvent() {
      const getParticipantsInfo = this.api.getParticipantsInfo();
      for (const getParticipantInfo of getParticipantsInfo) {
        this.api.executeCommand(
          "kickParticipant",
          String(getParticipantInfo.participantId)
        );
      }
      const numberOfParticipants = this.api.getNumberOfParticipants();
      if (numberOfParticipants === 1) {
        setTimeout(() => {
          this.$router.push({ name: "Events" });
        }, 3000);
      }
    },
    closeModalValidateCode() {
      this.isComponentModalActive = false;
      this.$buefy.toast.open({
        duration: 5000,
        position: "is-bottom",
        message: "Debe Ingresar el código para ver el evento",
        type: "is-danger",
      });
      this.$router.push({ name: "Events" });
    },
  },

  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
};
</script>

<style lang="scss" src="./Event.scss" scoped></style>
